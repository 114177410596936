.main-banner {
    background-image: url('../../../assets/main-banner3.jpg');
     background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8);
    color: white;

    .banner-text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }
    .sub-para {
        text-align: start;
        font-size: 20px;
    }

    .design1 {
        position: absolute;
        bottom: -37px;
        right: 88px;
    }

   
}

.services-container {

    .service-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        .list-item{
            cursor: pointer;
            /* height: 100px; */
             background-color: var(--gary-bg);
             padding: 1rem;
            border-radius: 5px;
            cursor: pointer;

            &:hover{
                transform: scale(1.01);
                transition-duration: 300ms;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
       }
 
            .brif-para{
                 display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                color: #9d9d9d;
            }
            .service-header{
                text-transform: uppercase;
                color: var(--blue);
                font-size: larger;
                font-weight: 500;
                text-align: center;
            }
            img{
                height: 200px;
                width: 200px;
                object-fit: cover;
                border-radius: 50%;
                background-color: white;
              
 
            }
        }
    }
}


.banner-middle{
    /* background-image: url('../../../assets/developer.jpg'); */
    background-size: cover;
    background: var(--gary-bg);
   background-repeat: no-repeat;
    background-position: center;
   background-attachment: fixed;
   /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5); */
   color: black;
   margin-top: 3rem;

   .container{
    padding-top: 70px;
        padding-bottom: 70px;
   }

   .right-sec{
    img{
        height: 400px;
    }
   }

   .inner-container{
    height: 100%;
    padding: 3rem 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
   }
}

.experience-container{
    .experience-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr  ;

         gap: 1rem;
       .list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f7f7f7;
         border-radius: 5px;
         padding: 1.2rem;
        cursor: pointer;
        flex-direction: column;
         font-size: larger;
        gap: 1rem;
           .img-list{
               img{
                   height: 50px;
                }
           }
           &:hover{
               transform: scale(1.01);
               transition-duration: 300ms;
               box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
       }
    }
}

.common-header {
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    margin: 2rem 0rem;
}

.common-sub-header {
    font-size: 28px;
    font-weight: 500;
     margin: 2rem 0rem;
}

.center-h-v{
    display: flex;
    justify-content: center;
    align-items: center;
}

.discover-more{
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 14px;
    &:hover{
        color: gray;
    }
}

.common-primary-button{
    background-color: var(--blue);
    color: white;
    border: none;
    height: 45px;
    border-radius: 10px;
    padding: 0px 15px;
    font-size: 15px;
}

.circle-design{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 64%;
    left: 4%;
    z-index: -1;
    img{
    height: 450px;
    }
}


 

  .contact-form-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
      border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    .form-group {
        margin-bottom: 15px;
      }
      .country-option {
        display: flex;
        align-items: center;
      }
      
      .country-flag {
        width: 24px;
        height: auto;
        margin-right: 10px;
      }
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
      
      input, textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      
      textarea {
        height: 100px;
      }
      
      .input-error {
        border-color: red;
      }
      
      .error-message {
        color: red;
        font-size: 0.875em;
      }
  }
  
/* model */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
     display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .modal-content {
        background: white;
        padding: 25px;
        border-radius: 8px;
        max-width: 600px;
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        position: relative;
        transform: translateY(-20px);
        transition: transform 0.3s ease;
      }
      
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
      
      .modal-header h2 {
        margin: 0;
        font-size: 1.5em;
      }
      
      .close-btn {
        background: transparent;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
      }
      
      .modal-body {
        /* max-height: 300px; */
        overflow-y: auto;
      }
      @media (max-width: 600px) {
        .modal-content {
          max-width: 95%;
          padding: 15px;
        }
      
        .modal-header h2 {
          font-size: 1.25em; /* Slightly smaller title font size on small screens */
        }
      }
      
      @media (max-width: 400px) {
        .modal-header h2 {
          font-size: 1em; /* Smaller title font size on very small screens */
        }
      
        .close-btn {
          font-size: 1.25em; /* Smaller close button on very small screens */
        }
      }
  }
  

/* model */
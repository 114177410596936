@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --blue: #007bff;
  --gary-bg: #f7f7f7; 
  --dark-gary-bg: #e7e7e7;
  --dark-bg:#000000

}

body {
  height: 100vh;
  overflow: auto;
  font-family: "Poppins", sans-serif;
}
a{
  text-decoration: unset;
  color: unset
}
@import url('./components/layout/layout.css');
@import url('./components/pages/Home/home.css');
@import url('./components/Common/common.css');
@import url('./components/pages/services/services.css');
@import url('./components/pages/Team/team.css');

